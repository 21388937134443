@import "../../../styles/dimensions";

$image-file-height: 30vh;
$empty-height : 50px;

.note-files {
  display: flex;
  overflow-x: auto;
  position: relative;
  &.empty {
    height: $empty-height;
    .note-image {
      > img, video {
        height: $empty-height;
      }
      &.placeholder {
        line-height: $empty-height;
      }
    }
  }
  .zoomed-picture {
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffffad;
    cursor: pointer;
    overflow: auto;
    > img {
      border: 1px solid black;
      cursor: default;
    }
    .maxed {
      overflow: auto;
    }
    &:not(.maxed) {
      display: flex;
      align-items: center;
      justify-content: center;
      > img {
        max-width: 100%;
      }
    }
    .icon {
      position: fixed;
      top: 5px;
      right: 5px;
      background: white;
    }

  }

  &.unique {
    .note-image {
      > img {
        height: auto;
        width: 100%;
      }
    }
  }
  .note-image {
    &.deleted:after {
      content: " ";
      position: absolute;
      background-color: #ab303063;
      display: block;
      width: 100%;
      height: calc(100% - 45px);
      top: 0;
      left: 0;
    }
    > img {
      height: $image-file-height;
      width: auto;
      cursor: pointer;
    }
    margin-right: $gutter-size;
    position: relative;
    > img {
      height: $image-file-height;
    }
    &.placeholder {
      line-height: $image-file-height;
    }
  }
}
