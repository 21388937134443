@import "../../styles/dimensions";
@import "../../styles/texts";
.thumbnails-container {
  > .title {
    font-size: $minor-title-font-size;
  }
  .thumbnails {
    overflow-x: auto;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    > video {
      &.alone {
        width: 100%;
      }
      &:not(.alone) {
        @media (max-width: 500px) {
          width: 90%;
        }
        @media (min-width: 501px) {
          width: 50%;
        }
        margin-right: $gutter-size;
      }
      margin-right: $gutter-size;
      display: inline-block;
      flex-shrink: 0;
    }
  }
}
