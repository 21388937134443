@import "../../styles/dimensions";
@import "../../styles/colors";

.video-thumbnail-state {
    border: 1px solid $border-color;
    border-radius: 5px;
    margin-right: $gutter-size;
    padding: $gutter-size;
    display: flex;
    align-items: center;
}