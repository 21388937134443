@import "../../styles/dimensions";
@import "../../styles/texts";
.videos-container {
  > .title {
    font-size: $minor-title-font-size;
  }
  video {
    width: 100vw;
    position: relative;
    left: -24px;
    height: auto;
    max-height: 100vh;
  }
  .video-controls-line {
    display: flex;
    justify-content: space-around;
    align-items: center;
    > .video-bound {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
  }
  .video-name {
    text-align: center;
  }
  .video-groups {
    display: flex;
    overflow-x: auto;
    > .video-group {
      margin-right: 1em;
      &.selected {
        border: 2px solid $my-primary-color;
      }
    }
  }
}
