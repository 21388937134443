@import "dimensions.scss";
html {
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
}

.app {
  padding: 64px $gutter-size $gutter-size;
  height: 100vh;
  .with-menu {
    padding-top: 2 * $gutter-size;
  }
}

.wrapper {
  height: 100%;
}
.hidden {
  display: none;
}
.with-margin-right {
  margin-right: $gutter-size;
}
.with-margin-top {
  margin-top: $gutter-size;
}
.with-margin-bottom {
  margin-bottom: $gutter-size;
}

.no-padding {
  padding: 0 !important;
  &.MuiButtonBase-root{
    padding: 0;
  }
}
.with-padding {
  padding: $gutter-size;
}

.unscrollable {
  overflow-y: hidden;
}


.list-horizontal-display{

  display: flex;
  flex-drection: row;
  padding: 0;
  overflow-x: auto;
  .MuiListItem-root {
    width: unset;
  }
}

.centered-item {
  justify-content: center !important;
}

.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-column {
  display: flex;
  flex-direction: column;
}

.invisible {
  visibility: hidden;
}
