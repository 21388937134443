@import "dimensions.scss";
@import "colors.scss";
.MuiDialogActions-root.bottom-button-bar {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  .MuiButtonGroup-root.button-group {
    flex-wrap: nowrap;
  }
}
.MuiButtonGroup-root.button-group {
  flex-wrap: wrap;
  &.spread {
    display: flex;
    justify-content: space-between;
  }
  &.bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  &.coloured {
    background-color: $my-primary-color;
    > button {
      color: white;
    }
  }
}

.MuiButton-root.button-action {
  background-color: #006064;
  color: white;
}

.loading-button {
  color: white !important;
}

.button-link a {
  text-decoration: none;
}


.fab {
  position: fixed !important;
  bottom: $gutter-size;
  right: $gutter-size;
}
