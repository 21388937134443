.ProseMirror {
    &[contenteditable=true] {
        padding: 1rem;
        border: 1px solid #1976d2;
        border-radius: 3px;
    }
    img {
      height: auto;
      max-width: 100%;
  
      &.ProseMirror-selectednode {
        outline: 3px solid #68cef8;
      }
    }
    pre {
      background: #0d0d0d;
      border-radius: 0.5rem;
      color: #fff;
      font-family: "JetBrainsMono", monospace;
      padding: 0.75rem 1rem;
  
      code {
        background: none;
        color: inherit;
        font-size: 0.8rem;
        padding: 0;
      }
    }
    code {
      background-color: rgba(#616161, 0.1);
      border-radius: 0.25em;
      box-decoration-break: clone;
      color: #616161;
      font-size: 0.9rem;
      padding: 0.25em;
    }
}