@import "../../styles/dimensions";
@import "../../styles/colors";

$bg-color : lighten($my-primary-color, 0.75);

.toolbar {
  display: flex;
  .title {
    flex-grow: 1;
  }
}
.side-menu {
  width: 250px;
  & a {
    text-decoration: none !important;
    color: black;
  }
}
