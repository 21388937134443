@import "src/styles/colors";
.app.quizz {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .quizz-rating {
    display: flex;
    width: 100%;
    position: relative;
    text-align: center;
    justify-content: center;
    .pass-button {
      position: absolute;
      top: -5px;
      right: 5px;
    }
    > .saving {
      position: absolute;
      right: calc(50% - 110px);
      top: -8px;
    }
    .refresh-button {
      position: absolute;
      bottom: -10px;
      left: 0px;
    }
  }
  .quizz-note {
    flex-grow: 0;
    flex-basis: calc(100vh - 56px - 30px - 26px);
    overflow-y: auto;
    &.question {
      &.ask-title .note-text h1, &.ask-value .note-text p {
        background-color: $unimportant-text-color;
        color: $unimportant-text-color;
        border-radius: 10px;
        position: relative;
        > span {
          color: transparent !important;
          > span {
            color: transparent !important;
          }
        }
        &:after{
          display: flex;
          width: 100%;
          height: 100%;
          margin: auto;
          position: absolute;
          top: 0;
          left: 0;
          content: "?";
          color: white;
          justify-content: center;
          align-items: center;
          font-size: 30px;
        }
      }
    }
  }
}
