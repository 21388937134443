@import "../../styles/colors.scss";

.loading-pane {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $transparent-background-color;
}
