
fraction {
  display: inline-grid;

  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;

  font-size: 0.8em; // You can change fraction size here. I recommend 0.5em ~ 0.8em
  vertical-align: top;
}

numer {
  border-bottom: 1px solid;
}
denom {
  display: block;
}

superposed {
  display: inline-flex;
  flex-direction: column;
  text-align: center;
  > up {
    font-size: 1.25em;
    line-height: 1em;
  }
  > down {
    font-size: 0.75em;
    line-height: 0.5em;
  }
}

upperposed {
  display: inline-flex;
  flex-direction: column;
  text-align: center;
  transform: translateY(-0.75em);
  > up {
    font-size: 0.75em;
    line-height: 0.5em;
  }
  > down {
    font-size: 1.25em;
    line-height: 1em;
  }
}
