.video-detail-container {
  > video {
    width: 100%;
  }
  > .controls {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .navigation {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
