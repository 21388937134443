@import "../../styles/layout";
@import "../../styles/buttons";
@import "../../styles/science.scss";
.note-card, .notes-list .MuiListItem-root > li {
  width: 100%;
}
.note-text {
//  white-space: pre-wrap;
}

.note-image {
  width: 100%;
}

.highlight {
  background-color: yellow;
  padding: 0 10px;
}
